import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { registerObserver } from 'react-perf-devtool';

var options = {
  timeout: 12000 // Load the extension after 12 sec.
};

function callback(measures) {
  // do something with the measures
}

registerObserver(options, callback);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
