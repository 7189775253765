import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from '@material-ui/core';
import { RestaurantRounded, SettingsRounded } from '@material-ui/icons';

const ChangeRestaurantModal = ({
  open = false,
  onClose = () => {},
  onSubmit = () => {},
  restaurants = [],
  history = {}
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Wählen Sie ein Restaurant</DialogTitle>
      <DialogContent>
        <List>
          {restaurants.map(r => (
            <ListItem
              button
              onClick={() => onSubmit(r.id)}
              key={r.id}
              selected={window.location.pathname.includes(r.id)}
            >
              <ListItemAvatar>
                <Avatar>
                  <RestaurantRounded />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={r.title ? r.title : r.id} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button fullWidth disabled>
          Neues Restaurant anlegen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeRestaurantModal;
