export const timeToBlock = (time, plus = 0) => {
  if (!time) {
    return NaN;
  }

  if (Math.floor(parseInt(time.split(':')[1])) > 60) {
    return NaN;
  }

  if (parseInt(time.split(':')[0]) > 24) {
    return NaN;
  }

  return (
    parseInt(time.split(':')[0]) * 4 +
    Math.floor((parseInt(time.split(':')[1]) + plus) / 15)
  );
};

export const blockToTime = block => {
  var h = Math.floor(block / 4);
  var m = (block % 4) * 15;
  if (m < 10) {
    m = '0' + m;
  }
  if (h >= 24) {
    h -= 24;
  }
  if (h < 10) {
    h = '0' + h;
  }
  return h + ':' + m;
};

export const pluralize = singular => {
  return singular + 's';
};

export const stringToId = str => {
  return str
    .toLocaleLowerCase()
    .replace(' ', '-')
    .replace(/ö/gm, 'oe')
    .replace(/ü/gm, 'ue')
    .replace(/ä/gm, 'ae')
    .replace(/ß/gm, 'ss')
    .replace(/\W/g, '');
};

export const navigatorVersion = () => {
  const ua = navigator.userAgent;
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1]
  };
};

export const noop = () => {};

// Takes in a list of fields with their type and sometimes a list of options and returns list of all fields with errors
export const checkRequiredFields = (array = [], obj = {}) => {
  var err = [];

  array.forEach(i => {
    var value = obj[i.key];

    if (i.type === 'array') {
      if (typeof value !== 'object' || !value.length) {
        err.push(i.key);
      }
    } else if (
      !value ||
      typeof value !== i.type ||
      (i.oneOf && !i.oneOf.includes(value))
    ) {
      err.push(i.key);
    }
  });

  return err;
};

// Returns a partial object and leaves out the provided keys;
export const partialSpread = (obj, leaveOut = []) => {
  var newObj = {};
  var arr = Object.keys(obj).filter(k => !leaveOut.includes(k));

  arr.forEach(key => {
    newObj[key] = obj[key];
  });

  return newObj;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((r, position) =>
    typeof r === 'object' ? { ...r, position } : r
  );
};

export const getTodaysDate = () => {
  const now = new Date(Date.now());
  const date = ('0' + now.getDate()).slice(-2);
  const month = ('0' + (now.getMonth() + 1)).slice(-2);
  const year = now.getFullYear();
  return year + '-' + month + '-' + date;
};

//from format 2020-11-20T10:30 to format 20.11.2020 10:30
export const formatedDateAndTime = dateAndTime => {
  let date = dateAndTime.split('T')[0];
  let year = date.split('-')[0];
  let month = date.split('-')[1];
  let day = date.split('-')[2];
  let time = dateAndTime.split('T')[1];

  return (
    day + '.' + month + '.' + year + (time !== undefined ? '  ' + time : '')
  );
};

// Exple : if the date in props is the 3rd Monday of March => will return 3
export const thisIsTheXthWeekdayOfTheMonth = date => {
  if (date.getDate() < 7) {
    return 1;
  }

  let dayOfMonthCount = new Date(JSON.parse(JSON.stringify(date)));
  dayOfMonthCount.setDate(1);
  console.log(dayOfMonthCount);
  console.log(date.getDate());
  let count = 1;
  for (let i = 2; i < date.getDate(); i++) {
    if (dayOfMonthCount.getDay() === date.getDay()) {
      count += 1;
    }
    dayOfMonthCount.setDate(i);
  }
  return count;
};
