import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo
} from 'react';
import ImageModal from '../App/Components/ImageModal';
import { FirebaseContext } from './FirebaseContext';

export const ImageContext = createContext();

const useCollection = (collectionPath = '', filter = []) => {
  const { db } = useContext(FirebaseContext);

  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(true);
  const [listener, setlistener] = useState([]);
  const [error, seterror] = useState(null);

  const stringifiedFilter = useMemo(() => JSON.stringify(filter), [filter]);

  let ref = !collectionPath.includes('//')
    ? db.collection(collectionPath)
    : null;

  useEffect(() => {
    if (listener && listener.length) {
      listener.forEach(unsubsribe => unsubsribe());
    }

    if (ref) {
      setloading(true);
      setdata([]);
      seterror(null);

      filter.forEach(rule => {
        ref = ref.where(...rule);
      });

      let unsubscribe = ref.onSnapshot(
        querySnapshots => {
          let arr = [];

          querySnapshots.forEach(doc => {
            arr.push({ ...doc.data(), id: doc.id });
          });

          setdata(arr);
          setloading(false);
        },
        err => {
          seterror(err.message);
          setloading(false);
        }
      );

      setlistener([unsubscribe]);
    }
    return () => {
      if (listener && listener.length) {
        listener.forEach(unsubsribe => unsubsribe());
      }
    };
  }, [db, collectionPath, stringifiedFilter]);

  return { ref, data, loading, error };
};

const ImageContextProvider = ({ children }) => {
  const [modal, setmodal] = useState(null);
  const { restaurantId, storageRef, db } = useContext(FirebaseContext);

  const restaurant = restaurantId || window.location.pathname.split('/')[1];

  const [currentFolder, setcurrentFolder] = useState('general');

  const [currentImage, setcurrentImage] = useState(null);

  const [selectedImages, setselectedImages] = useState([]);

  const [imageLoading, setimageLoading] = useState(false);
  const [imageError, setimageError] = useState(false);

  const folders = useCollection(`restaurants/${restaurant}/imageFolders`);

  const images = useCollection(
    `restaurants/${restaurant}/images`,
    currentFolder !== 'general' ? [['folder', '==', currentFolder]] : []
  );

  const uploadImage = async (image, folder = 'general', id = null) => {
    const ref = images.ref;

    let doc;

    let { size, name: imageName, type } = image;

    if (id) {
      doc = await ref.doc(id).update({
        imageName,
        originalName: imageName,
        size,
        type,
        thumbnails: [],
        updatedAt: Date.now()
      });

      doc = await ref.doc(id).get();

      folder = doc.data().folder;
    } else {
      doc = await ref.add({
        folder,
        originalName: imageName,
        imageName,
        size,
        type,
        alt: '',
        tags: [],
        thumbnails: [],
        createdAt: Date.now()
      });

      id = doc.id;
    }

    const imageType = `.` + imageName.split('.').slice(-1)[0];

    imageName = imageName.replace(imageType, '') + '_id_' + id + imageType;

    const reference = `/restaurants/${restaurant}/images/${folder}/${imageName}`;

    const fileRef = storageRef.child(reference);

    await fileRef.put(image);

    const url = await fileRef.getDownloadURL();

    await ref.doc(id).update({ reference, imageName, url });

    return {
      url,
      id
    };
  };

  useEffect(() => {
    setcurrentImage(null);
    setselectedImages([]);
  }, [currentFolder]);

  const deleteImage = async (id, deleteFile = true) => {
    const ref = images.ref.doc(id);

    const doc = await ref.get();

    if (!doc.exists) {
      throw new Error("Can't find Doc with id of " + id);
    }

    const { reference } = doc.data();

    const fileRef = storageRef.child(reference);

    await fileRef.delete();

    if (deleteFile) {
      await ref.delete();
    }

    return true;
  };

  const replaceImage = async (image, imageName, id) => {
    const ref = images.ref.doc(id);

    await deleteImage(id, false);

    const newImage = await uploadImage(image, imageName, null, id);

    return newImage;
  };

  const createFolder = async (name = '') => {
    const reference = `/restaurants/${restaurant}/images/${name}`;

    const data = {
      name,
      reference
    };

    const id = name.toLocaleLowerCase();

    await folders.ref.doc(id).set(data);

    return id;
  };

  const deleteFolder = async (id, deleteImages = true) => {
    await folders.ref.doc(id).delete();

    if (deleteImages) {
      const folderRef = storageRef.child(
        `/restaurants/${restaurant}/images/${id}`
      );

      await folderRef.delete();

      const imageDocs = await images.ref.where('folder', '==', id).get();

      const promises = [];

      imageDocs.forEach(doc => promises.push(doc.ref.delete()));

      await Promise.all(promises);
    } else {
      const imageDocs = await images.ref.where('folder', '==', id).get();

      const promises = [];

      imageDocs.forEach(doc =>
        promises.push(doc.ref.update({ folder: 'general' }))
      );

      await Promise.all(promises);
    }

    setcurrentFolder(cF => (cF === id ? 'general' : cF));

    return;
  };

  const closeModal = () => setmodal(null);

  const openImageModal = (
    onSubmit = null,
    options = { multiple: false, id: false }
  ) => {
    setmodal({ onSubmit, ...options, open: true });
    setselectedImages([]);
    setcurrentImage(null);
  };

  const handleSubmit = async () => {
    let selected = selectedImages.length ? selectedImages : [currentImage];

    if (!modal.id) {
      selected = selected.map(x => images.data.find(i => i.id === x).url);
    }

    if (!modal.multiple) {
      selected = selected[0];
    }

    await modal.onSubmit(selected);

    setmodal(null);
  };

  const handleImageUpload = async e => {
    try {
      console.log(e.target.files);
      setimageLoading(true);
      const { files = [] } = e.target;

      let promises = [];

      for (let index = 0; index < files.length; index++) {
        const image = files[index];
        if (image.size > 1000000) {
          setimageError(
            'Die maximale Bildgröße beträgt 1 MB. Bitte wählen Sie ein anderes Bild aus oder verkleinern Sie dieses.'
          );
        } else {
          promises.push(uploadImage(image, currentFolder));
        }
      }
      if (!imageError) {
        const images = await Promise.all(promises);
        setimageLoading(false);
        setcurrentImage(images[images.length - 1].id || null);
      } else {
        setimageLoading(false);
        return;
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const getUrl = async (id, thumbnail = false) => {
    const ref = db.collection(`restaurants/${restaurant}/images`).doc(id);

    const doc = await ref.get();

    console.log(doc.exists);

    if (!doc.exists) {
      return null;
    }

    const { url = null, thumbnails = [] } = doc.data();

    if (thumbnail && thumbnails.length) return thumbnail[0];

    return url;
  };

  const urlToId = async imgUrl => {
    // url format :
    // https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/restaurants%2Fneo-heidelberg%2Fmenu%2Fmeal-Weinpaket%206%20Flaschen%20TerroirshvhqPRR98IJbJZgiTC0N-Feature_Weintasting_Wein.jpg?alt=media&token=eaaaa174-d62c-4d87-ab4a-00f998c9d229

    const cleanUrl = imgUrl
      .replace(
        'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/',
        '/'
      )
      .split('%2F');

    const reference = decodeURI(cleanUrl.join('/').split('?')[0]);
    let imageName = decodeURI(cleanUrl.pop().split('?')[0]);
    console.log(reference);
    console.log(imageName);

    const imageRef = storageRef.child(reference);
    const metadata = await imageRef.getMetadata();
    console.log(metadata);
    const ref = images.ref;
    const doc = await ref.add({
      folder: 'essen',
      originalName: imageName,
      imageName,
      size: metadata.size,
      type: metadata.contentType,
      alt: '',
      tags: [],
      thumbnails: [],
      createdAt: Date.now(),
      url: imgUrl,
      reference
    });

    const id = doc.id;

    const imageType = `.` + imageName.split('.').slice(-1)[0];

    imageName = imageName.replace(imageType, '') + '_id_' + id + imageType;

    await ref.doc(id).update({ imageName });

    return {
      id
    };
  };

  const open = !!modal && modal.open;

  return (
    <ImageContext.Provider
      value={{
        modal,
        handleSubmit,
        folders,
        images,
        uploadImage,
        deleteImage,
        replaceImage,
        createFolder,
        deleteFolder,
        currentFolder,
        setcurrentFolder,
        currentImage,
        setcurrentImage,
        openImageModal,
        handleImageUpload,
        selectedImages,
        setselectedImages,
        getUrl,
        urlToId,
        imageLoading,
        imageError,
        setimageError
      }}
    >
      <ImageModal open={open} onClose={closeModal} />
      {children}
    </ImageContext.Provider>
  );
};

export default ImageContextProvider;
