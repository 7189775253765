import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo
} from 'react';
import { timeToBlock, pluralize } from '../utils/helperFunctions';
import { FirebaseContext } from './FirebaseContext';
import { withRouter } from 'react-router';

export const DashboardContext = createContext();

const DashboardContextProvider = ({ children, history }) => {
  const { db, confirmationDialogHandler } = useContext(FirebaseContext);
  const [currentRestaurant, setcurrentRestaurant] = useState(false);
  const [restaurantData, setrestaurantData] = useState({ loaded: false });
  const [restaurantDataListener, setrestaurantDataListener] = useState([]);
  const [currentSpace, setcurrentSpace] = useState(false);
  const [space, setspace] = useState('');
  const [currentDate, setcurrentDate] = useState(false);
  const [dateData, setdateData] = useState(false);
  const [bookingData, setBookingData] = useState({ loaded: false });
  const [currentTime, setcurrentTime] = useState();
  const [listener, setlistener] = useState([]);
  const [online, setonline] = useState(true);
  const [focus, setfocus] = useState(false);
  const [relocate, setrelocate] = useState(false);
  const [editViews, seteditViews] = useState(false);
  const [tableSwitch, setTableSwitch] = useState(false);

  useEffect(() => {
    if (restaurantDataListener.length) {
      restaurantDataListener.forEach(unsubscribe => unsubscribe());
    }

    if (currentRestaurant) {
      let unsubscribe = db
        .collection('restaurantData')
        .doc(currentRestaurant)
        .onSnapshot(
          function (doc) {
            if (!doc.exists) {
              console.log('does not exist');
              setrestaurantData({ loaded: true, exists: false });
              return;
            }

            console.log('exists');
            let data = doc.data();

            let tables = data.tables.map(t => ({
                ...t,
                bookings: [],
                id: t.name,
                tableId: t.id
              })),
              tablePosition = tables.map(t => t.name);

            setrestaurantData({
              shifts: [],
              ...data,
              spaceData: {},
              tables,
              tbls: data.tables,
              tablePosition,
              loaded: true,
              exists: true
            });

            if (
              data.productsOwned.includes('reservation') &&
              !data.spaces.length
            ) {
              return history.push(`/${currentRestaurant}/onboarding/welcome`);
            }
          },
          function (error) {
            console.log('error!');
            setrestaurantData({ loaded: true, exists: false });
            return;

            if (error.message === 'Missing or insufficient permissions.') {
              window.location.replace('/');
            }
          }
        );

      setrestaurantDataListener([unsubscribe]);
    }

    return () => {
      restaurantDataListener.forEach(unsubscribe => unsubscribe());
    };
  }, [currentRestaurant]);

  useEffect(() => {
    const whenOffline = () => {
      setonline(false);
      confirmationDialogHandler({
        title: 'Das Internet ist unterbrochen.',
        maxWidth: 'xs',
        description:
          'Es kann sein, dass der Stand der Reservierungen nicht aktuell ist solange keine Internetverbindung besteht.'
      });
    };

    const whenOnline = () => {
      // console.log("🦄🎊🔥 we're back online!");
      setonline(true);
    };

    window.addEventListener('offline', whenOffline);

    window.addEventListener('online', whenOnline);
    return () => {
      window.removeEventListener('offline', whenOffline);
      window.removeEventListener('online', whenOnline);
    };
  }, []);

  useEffect(() => {
    if (currentDate && dateData.loaded && dateData.date === currentDate) {
      if (listener.length) {
        listener.forEach(unsubscribe => unsubscribe());
      }

      let unsubscribe = db
        .collection('requestsV03')
        .where('restaurant', '==', currentRestaurant)
        .where('date', '==', currentDate)
        .orderBy('createdAt', 'asc')
        .onSnapshot(function (querySnapshot) {
          let bookingPosition = [],
            bookings = [],
            tables = restaurantData.tables.map(t => {
              return { ...t, bookings: [] };
            }),
            index = 0,
            counter = 0;

          setcurrentTime(
            timeToBlock(
              new Date(new Date() - 6 * 60 * 60 * 1000).toTimeString()
            ) + 24
          );

          querySnapshot.forEach(function (doc) {
            var data = doc.data(),
              className = '',
              timeSpend = 0,
              totalTime = 0,
              startTimeInBlocks = timeToBlock(data.time);

            var endTimeInBlocks = data.reservationLength
              ? startTimeInBlocks + data.reservationLength
              : data.endTimeInBlocks +
                dateData.spaces[data.space].openFromInBlocks;

            var reservationLength = data.reservationLength
              ? data.reservationLength
              : endTimeInBlocks - startTimeInBlocks;

            bookingPosition.push(doc.id);

            if (data.validTill && data.validTill < new Date().getTime()) {
              doc.ref.delete();
            }

            if (dateData.past && data.status !== 'failed' && !data.done) {
              doc.ref.update({ done: true });
            }

            if (data.createdAt === undefined) {
              doc.ref.update({ createdAt: Date.now() - 1800000 });
            }

            if (!data.occassion) {
              doc.ref.update({
                occassion: data.space === 'bar' ? 'cocktails' : 'nikkei-sushi'
              });
            }

            if (
              dateData.today &&
              data.status !== 'failed' &&
              endTimeInBlocks + 4 >= currentTime
            ) {
              if ((data.started || data.started === 0) && !data.done) {
                className = '-active';
                timeSpend = currentTime - startTimeInBlocks;
                totalTime = reservationLength;
              }

              if (
                currentTime >= startTimeInBlocks &&
                !data.started &&
                data.started !== 0 &&
                !data.done
              ) {
                className = '-alert';
              }
            } else if (
              (dateData.past ||
                (dateData.today && endTimeInBlocks + 4 < currentTime)) &&
              data.status !== 'failed'
            ) {
              className = '-inPast';
            }

            index++;

            var space = !data.tables.every(t => t.includes('Bar '))
              ? data.space
              : 'bar';
            var btables = data.tables.map(t => t.replace('Bar ', ''));

            bookings.push({
              ...data,
              id: doc.id,
              startTimeInBlocks,
              endTimeInBlocks,
              reservationLength,
              active: { class: className, timeSpend, totalTime },
              newEntry: data.createdAt + 1800000 > new Date().getTime(),
              index,
              space,
              tables: btables
            });

            if (!data.done && data.status !== 'failed') {
              data.tables.forEach(t => {
                var table = t;

                if (restaurantData.tablePosition.indexOf(table) < 0) {
                  table = restaurantData.tablePosition.find(t => t.includes(t));
                }

                if (!table) {
                  return;
                }

                tables[
                  restaurantData.tablePosition.indexOf(table)
                ].bookings.push({
                  start:
                    startTimeInBlocks -
                    dateData.spaces[data.space].openFromInBlocks,
                  end:
                    endTimeInBlocks -
                    dateData.spaces[data.space].openFromInBlocks,
                  time: data.time,
                  newEntry: data.createdAt + 1800000 > new Date().getTime(),
                  blocked: data.blocked,
                  id: doc.id
                });
              });
            }
          });

          setBookingData({
            tables,
            bookings,
            loaded: true
          });
        });

      setlistener([unsubscribe]);
    }

    return () => {
      listener.forEach(unsubscribe => unsubscribe());
    };
  }, [currentDate, dateData]);

  const handleFocus = (type, target) => {
    if (!type) {
      setfocus(false);
    } else {
      setfocus({
        type,
        target,
        object: bookingData[pluralize(type)].find(i => i.id === target),
        fullScreen: false
      });
    }
  };

  const updateViews = (cV, action = 'edit') => {
    if (!cV || !cV.id || !cV.name || !cV.standardSpace || !currentRestaurant) {
      return;
    }

    if (action === 'delete') {
      let views = Array.from(restaurantData.views).filter(v => v.id !== cV.id);

      setrestaurantData({ ...restaurantData, views });

      return db
        .collection('restaurantData')
        .doc(currentRestaurant)
        .update({ views });
    }

    let { id, name, filter, standardSpace } = cV;

    let views = Array.from(restaurantData.views);

    if (id === 'new') {
      id = name.toLocaleLowerCase().replace(' ', '-');

      if (
        restaurantData.views &&
        restaurantData.views.find(v => v.name === name)
      ) {
        return;
      }

      views.push({ id, name, filter, standardSpace });
    } else {
      views = [
        ...views.filter(v => v.id !== id),
        { id, name, filter, standardSpace }
      ];
    }

    setrestaurantData({ ...restaurantData, views });

    return db
      .collection('restaurantData')
      .doc(currentRestaurant)
      .update({ views });
  };

  const productsOwned =
    restaurantData && restaurantData.productsOwned
      ? restaurantData.productsOwned
      : ['general'];

  const productsOwnedChanged = useMemo(
    () => JSON.stringify(productsOwned),
    [restaurantData.productsOwned]
  );

  return (
    <DashboardContext.Provider
      value={{
        currentRestaurant,
        setcurrentRestaurant,
        setcurrentSpace,
        restaurantData,
        online,
        focus,
        setfocus,
        handleFocus,
        relocate,
        setrelocate,
        editViews,
        seteditViews,
        updateViews,
        tableSwitch,
        setTableSwitch,
        productsOwned,
        productsOwnedChanged
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default withRouter(DashboardContextProvider);
