import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../Contexts/FirebaseContext';
import ChangeRestaurantModal from './Auth/ChangeRestaurantModal';

const Start = ({ history }) => {
  const { db, data, setrestaurantId } = useContext(FirebaseContext);

  const [showRestaurantSelection, setShowRestaurantSelection] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);

  useEffect(() => {
    if (data.user !== undefined && !data.user) {
      //history.push('/signIn');
    }

    if (data.user !== undefined && data.user) {
      (async () => {
        const ref = db.collection('users').doc(data.user.uid);

        const userData = await ref.get();

        if (!userData.exists) {
          return true;
        }

        let { restaurants = [], restaurant, role } = userData.data();

        if (!restaurants.length) {
          const restDoc = await db
            .collection('restaurants')
            .doc(restaurant)
            .get();

          const { name } = restDoc.data();

          ref.update({
            restaurants: [{ id: restaurant, title: name, role }]
          });

          restaurants = [{ id: restaurant, title: name, role }];
        }

        //Choose the restaurant if more than one
        if (restaurants.length > 1) {
          setRestaurants(restaurants.filter(r => r.role === 'admin'));
          setShowRestaurantSelection(true);
        } else {
          setSelectedRestaurant(restaurants[0].id);
        }

        if (!!selectedRestaurant) {
          setrestaurantId(selectedRestaurant);

          history.push(`/${selectedRestaurant}/general`);
        }
      })();
    }
  }, [data.user, history.location, selectedRestaurant]);

  const handleRestaurantSelection = selection => {
    setSelectedRestaurant(selection);
    setShowRestaurantSelection(false);
  };

  return (
    <div>
      <ChangeRestaurantModal
        restaurants={restaurants}
        open={showRestaurantSelection}
        onClose={() => setShowRestaurantSelection(false)}
        onSubmit={handleRestaurantSelection}
        history={history}
      />
    </div>
  );
};

export default Start;
