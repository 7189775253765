import React, { createContext, useState, useEffect } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const darkmodeEnabled = () => {
  let preferesDarkMode = true;

  if (window.matchMedia) {
    preferesDarkMode = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches;
  }

  if (window.localStorage && window.localStorage.getItem('darkMode') !== null) {
    preferesDarkMode = window.localStorage.getItem('darkMode') !== 'false';
  }

  return preferesDarkMode;
};

const palette = {
  darkMode: {
    type: 'dark',
    primary: {
      main: '#0bdbc5',
      contrastText: '#121212',
      light: 'rgba(11,219,197,0.6)'
    },
    secondary: {
      main: '#f50057',
      contrastText: '#ffffff'
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.54)',
      disabled: 'rgba(255, 255, 255, 0.38)',
      hint: 'rgba(255, 255, 255, 0.38)'
    },
    divider: 'rgba(255,255,255, 0.2)'
  },
  lightMode: {
    type: 'light',
    primary: {
      main: '#10CCB9',
      contrastText: '#252627',
      light: 'rgba(11,219,197,0.6)'
    },
    secondary: {
      main: '#f50057',
      contrastText: '#ffffff'
    },
    text: {
      primary: 'rgba(37, 38, 39, 0.87)',
      secondary: 'rgba(37, 38, 39, 0.54)',
      disabled: 'rgba(37, 38, 39, 0.38)',
      hint: 'rgba(37, 38, 39, 0.38)'
    }
  }
};

export const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
  const [darkMode, setdarkMode] = useState(darkmodeEnabled());

  useEffect(() => {
    if (window.localStorage) {
      window.localStorage.setItem('darkMode', darkMode);
    }

    if (darkMode) {
      document.getElementsByTagName('body')[0].classList.remove('lightMode');
    } else {
      document.getElementsByTagName('body')[0].classList.add('lightMode');
    }
  }, [darkMode]);

  const theme = createMuiTheme({
    palette: darkMode ? palette.darkMode : palette.lightMode,
    typography: {
      h1: {
        fontSize: '2.5rem'
      },
      h2: {
        fontSize: '2rem'
      },
      subtitle1: {
        fontSize: '1.5rem',
        lineHeight: '2rem'
      },
      subtitle2: {
        fontSize: '1.25rem'
      }
    }
  });

  return (
    <ThemeContext.Provider value={{ darkMode, setdarkMode }}>
      <div className='App'>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </div>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
