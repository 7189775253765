import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ThemeContextProvider from './Contexts/ThemeContext';
import FirebaseContextProvider from './Contexts/FirebaseContext';
import DashboardContextProvider from './Contexts/DashboardContext';
import ImageContextProvider from './Contexts/ImageContext';
import LoadingScreen from './App/Components/LoadingScreen';
import Start from './App/Start';
import SignIn from './App/Auth/SignIn';
import ForgotPassword from './App/Auth/ForgotPassword';
import './App.css';

const SettingsDashboard = lazy(() => import('./App/index.js'));

const App = () => {
  return (
    <BrowserRouter>
      <ThemeContextProvider>
        <FirebaseContextProvider>
          <DashboardContextProvider>
            <ImageContextProvider>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Route path='/' component={Start} exact={true} />
                  <Route
                    path='/:restaurantId/:feature'
                    component={SettingsDashboard}
                    exact={true}
                  />
                  <Route
                    path='/forgotPassword'
                    component={ForgotPassword}
                    exact={true}
                  />
                  <Route path='/signIn' component={SignIn} exact={true} />
                </Switch>
              </Suspense>
            </ImageContextProvider>
          </DashboardContextProvider>
        </FirebaseContextProvider>
      </ThemeContextProvider>
    </BrowserRouter>
  );
};

export default App;
